<template>
  <div class="container">
    <div class="card">
      <div class="card-header">
        <!-- <h4>{{ $t("ROUTES.NEW_WALLET") }}</h4> -->
      </div>
      <form class="form" @submit.stop.prevent="onSubmit">
        <div class="card-body">
          <div class="form-group row">
            <label class="col-form-label text-right col-lg-3 col-sm-12">
              {{ $t("wallets.selectCrypto") }}
            </label>
            <div class="col-lg-6 col-md-9 col-sm-12">
              <multiselect
                v-model="selectedCrypto"
                deselect-label=""
                select-label=""
                selected-label=""
                track-by="name"
                label="name"
                :custom-label="currencySelectCustomLabel"
                :show-labels="false"
                :placeholder="$t('FORM.PLEASE_SELECT')"
                :options="tradeCryptos"
                :allow-empty="false"
                open-direction="bottom"
                :class="{ 'is-invalid': error }"
              >
                <!-- <template slot="singleLabel" slot-scope="{ option }">{{ `${option.name} (${option.code})` }}</template> -->
                <template slot="singleLabel" slot-scope="{ option }">
                  <div class="row align-items-center">
                    <div class="col-1">
                      <img
                        :src="option.icon"
                        style="max-width: 18px; max-height: 18px;"
                      />
                    </div>
                    <div class="row col">
                      <span>{{ `(${option.code})` }}</span
                      ><span class="ml-2">{{ option.name }}</span>
                    </div>
                  </div>
                </template>
                <template slot="option" slot-scope="{ option }">
                  <div class="row align-items-center">
                    <div class="col-1">
                      <img
                        :src="option.icon"
                        style="max-width: 18px; max-height: 18px;"
                      />
                    </div>
                    <div class="row col">
                      <span>{{ `(${option.code})` }}</span
                      ><span class="ml-2">{{ option.name }}</span>
                    </div>
                  </div>
                </template>
                <template slot="noOptions">{{
                  $t("COMMON.NO_DATA_AVAILABLE")
                }}</template>
                <template slot="noResult">{{
                  $t("COMMON.NO_DATA_AVAILABLE")
                }}</template>
              </multiselect>
            </div>
          </div>
          <!-- <div class="form-group row">
            <label class="col-form-label text-right col-lg-3 col-sm-12">
              {{ $t("wallets.currencyName") }}
            </label>
            <div class="col-lg-6 col-md-9 col-sm-12">
              <input
                type="text"
                class="form-control"
                placeholder=""
                :value="selectedCrypto?.name || ''"
                disabled
              />
            </div>
          </div> -->
          <!-- <div class="form-group row">
            <label class="col-form-label text-right col-lg-3 col-sm-12">
              {{ $t("wallets.currencyCode") }}
            </label>
            <div class="col-lg-6 col-md-9 col-sm-12">
              <input
                type="text"
                class="form-control"
                placeholder=""
                :value="selectedCrypto?.code || ''"
                disabled
              />
            </div>
          </div> -->
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-lg-9 ml-lg-auto d-flex justify-content-end">
              <button
                type="submit"
                class="btn btn-success mr-2"
                :disabled="isSubmitting"
              >
                {{
                  isSubmitting
                    ? $t("AUTH.LOGIN.LOADING_MESSAGE_TITLE")
                    : $t("FORM.SUBMIT")
                }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
// import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/store/modules/breadcrumbs";
import { mapState } from "vuex";
import Multiselect from "vue-multiselect";

export default {
  props: ["showDeleteButton"],
  components: { Multiselect },
  data() {
    return {
      selectedCrypto: null,
      error: false,
      isSubmitting: false
    };
  },
  computed: {
    ...mapState({
      isLoading: state => state.wallets.isLoading,
      tradeCryptos: state => state.wallets.tradeCryptos
    })
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("ROUTES.NEW_WALLET") }
    ]);
  },
  methods: {
    async onSubmit() {
      this.error = false;
      if (!this.selectedCrypto) {
        this.error = true;
        return;
      }
      const form = {
        currencyName: this.selectedCrypto.name,
        currencyCode: this.selectedCrypto.code,
        currencyIcon: this.selectedCrypto.icon,
        isActive: true
      };
      this.isSubmitting = true;
      try {
        await this.$store.dispatch("wallets/CREATE_WALLET", form);
        this.$root.$bvToast.toast(this.$t("FORM.SUBMITTED"), {
          title: this.$t("FORM.SUCCESS_TITLE"),
          variant: "success",
          autoHideDelay: 5000
        });
        this.$router.push({ name: "wallets" });
      } catch (error) {
        const msg = error?.response?.data?.message || this.$t("FORM.ERROR");
        this.$root.$bvToast.toast(msg, {
          title: this.$t("FORM.ERROR_TITLE"),
          variant: "danger",
          autoHideDelay: 5000
        });
      } finally {
        this.isSubmitting = false;
      }
    },
    currencySelectCustomLabel({ name, code }) {
      return `${name} (${code})`;
    }
  },
  created() {
    this.$store.dispatch("wallets/GET_CRYPTOS");
  }
};
</script>

<style lang="scss" scoped>
.validation {
  &.error {
    color: #f64e60 !important;
  }
}
</style>
